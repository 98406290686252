import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Training from './pages/Training/Training';
import Portfolio from './pages/Portfolio/Portfolio';
import Layout from './components/layout';
import Internship from './pages/internship/Internship';
import './index.css'
import AboutUs from "./pages/AboutUs";
import Lenis from '@studio-freight/lenis';
import Services from './pages/Services/services'
import MainServices from './pages/Services/MainServices'
import Blog from './pages/blog/Blog';
import Openings from './pages/Opening';
import ErrorPage from './components/ErrorPage';
import SelectedTraining from './pages/Training/selectedTraining';
import BootCamp from './pages/boot_camp/BootCamp';
import ContactUsPage from './pages/Contact/contactUsPage';
import MainTestimonials from './pages/MainTestimonials/TestimonialsPage';
import BlogList from './pages/blog/BlogList';
import InternshipPost from './pages/internship/InternshipPost';
import AccountDeletion from './pages/AccountDeletion';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  
  const lenis = new Lenis()

lenis.on('scroll', (e) => {
  // console.log(e)
})

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
  return (
    <Router>
      <Routes>
        
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="internship" element={<Internship />} />
          <Route path="internship/:internship" element={<InternshipPost/>} />
          <Route path="training" element={<Training />} />
          <Route path="bootcamp" element={< BootCamp />} />
          <Route path="training/:training" element={<SelectedTraining />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="blogs" element={<BlogList/>}/>
          <Route path="blogs" element={<Home/>}/>
          <Route path="testimonials" element={<MainTestimonials/>}/>
          <Route path="blog/:name" element={<Blog />} />
          <Route path="contact" element={<ContactUsPage />} />
          <Route path="services/:service" element={<Services />} />
          <Route path="services" element={<MainServices />} />
          <Route path="openings" element={<Openings />} />
          <Route path="account-deletion" element={<AccountDeletion />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
