import React, { useEffect, useRef } from "react";
import programsData from "./ProgramsData";
import { Link, useParams } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import AppFaq from "./Faq";
import FaqsData from "./FaqData";

const ProgramInfo = () => {
  const { training } = useParams();
  let content = useRef();

  let filter = Object.keys(programsData).filter((e) => e.includes(training));
  let renderContent =
    filter.length > 0 ? programsData[filter[0]] : "Software Development";

  useEffect(() => {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);
  }, [training]);

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const formattedBody = renderContent.description
    ? renderContent.description.split("\n").map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph.split("\t").map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line}
            {lineIndex < paragraph.split("\n").length - 1 && (
              <>&nbsp;&nbsp;&nbsp;</>
            )}
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    ))
    : null;

  // Ensure renderContent is defined before accessing its properties
  const imageUrl = renderContent?.imageURL || "";
  const scheduleData = renderContent.schedule || {};

  return (
    <main className="min-h-screen w-full bg-black font-noto mb-0">
      <header className="w-full bg-black lg:mt-20 mt-20 mb-20 flex items-center">
        <div
          className="w-full relative min-h-[40vh] md:min-h-[80vh] h-[30vh] bg-cover bg-center flex justify-center items-center flex-col px-[clamp(1em,8vw,5em)]"
          style={{ backgroundImage: `url(${imageUrl})`, objectFit: "cover" }}
        >
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-black opacity-70"></div>
          </div>

          <h1 className="text-[clamp(2.7em,6vw,4.5em)] absolute max-w-[95%] md:max-w-[70%] lg:max-w-[55%] text-center">
            {renderContent && renderContent?.name}
          </h1>
        </div>
      </header>

      <section
        ref={content}
        className="flex flex-col font-noto min-h-[10svh] md:min-h-[90vh] items-center gap-20 md:gap-10 mt-20 md:mt-0"
      >
        <div className="grid flex items-center justify-center lg:mx-[120px] mx-[17px] mt-[50px] mb-[100px]">
          <h1 className="text-[30px] sm:text-lg md:text-xl pb-7 lg:text-2xl xl:text-3xl">
            Course Overview
          </h1>
          <p className="text-[20px]">{formattedBody}</p>
        </div>

        <div className="schedule-section font-noto lg:mx-[120px]">
          <div className="text-center mb-[50px]">
            <h1 className="text-[30px] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
              Cohort Schedule
            </h1>
            <p className="text-[20px]">Choose any convenient class</p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[20px] "
          >
            <div className="bg-[#1E1E1E] mx-[17px] text-white h-[400px] rounded-xl pt-[66px] pb-[66px] items-center justify-center text-center">
              <p>Duration</p>
              <b className="text-[36px] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
                {renderContent?.duration}
              </b>

              {(renderContent?.fromDate && renderContent?.toDate) ? (
                <>
                  <p className="mt-[60px]">Date:</p>
                  <div className="flex flex-wrap flex_control justify-center">
                    <b className="lg:text-[16px] text-[16px] sm:text-lg md:text-xl lg:mx-auto mx-[10px] px-10 lg:px-[20px] text-center">
                      {renderContent.fromDate} – {renderContent.toDate}
                    </b>
                  </div>
                </>
              ) : (
                <div className="flex flex-col justify-center items-center h-full">
                  <p className="text-[20px] mt-[20px]">No date available</p>
                </div>
              )}
            </div>


            <div
              className="bg-[#1E1E1E] mx-[17px] text-white h-[400px] rounded-xl pb-[66px] items-center justify-center text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: Object.keys(scheduleData).length > 4 ? "80px" : "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              {Object.keys(scheduleData).some(day => daysOfWeek.includes(day)) ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {Object.keys(scheduleData).map((day, index) => (
                    daysOfWeek.includes(day) && scheduleData[day]?.length > 0 && (
                      <div key={index} style={{ width: "50%", padding: "10px" }}>
                        <p style={{ fontSize: "20px" }}>{day}:</p>
                        <p
                          className="text-[14px] font-noto font-bold lg:text-[16px] mb-[20px]"
                          style={{
                            fontSize: Object.keys(scheduleData).length > 3 ? "14px" : "20px",
                          }}
                        >
                          {scheduleData[day].map((entry, entryIndex) => (
                            <React.Fragment key={entryIndex}>
                              {entry.subject && (
                                <>
                                  <b>{entry.subject}:</b>{" "}
                                </>
                              )}
                              {entry.time}
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                      </div>
                    )
                  ))}
                </div>
              ) : (
                <>
                  <b className="lg:text-[36px] text-[28px] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl"
                    style={{
                      fontSize: (scheduleData.Morning?.length || 0) > 1 ? "28px" : "36px",
                    }}>
                    Monday - Friday
                  </b>
                  <p style={{
                    marginTop: (scheduleData.Morning?.length || 0) + (scheduleData.Afternoon?.length || 0) > 2 ? "10px" : "80px",
                    fontSize: (scheduleData.Morning?.length || 0) > 4 ? "14px" : "20px",
                  }}>Morning:</p>
                  <p
                    className="mx-[10px] text-[14px] font-noto font-bold lg:text-[16px] mb-[20px]"
                    style={{
                      fontSize: (scheduleData.Morning?.length || 0) > 4 ? "14px" : "20px",
                    }}
                  >
                    {scheduleData.Morning?.map((entry, index) => (
                      <React.Fragment key={index}>
                        {entry.subject && (
                          <>
                            <b>{entry.subject}:</b>{" "}
                          </>
                        )}
                        {entry.time}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                  <p style={{
                    fontSize: (scheduleData.Morning?.length || 0) > 4 ? "14px" : "20px",
                  }}>Afternoon:</p>
                  <p className="mx-auto font-noto mb-1 text-[14px] font-bold text-center mb-[20px]"
                    style={{
                      fontSize: (scheduleData.Morning?.length || 0) > 4 ? "14px" : "20px",
                    }}>
                    {scheduleData.Afternoon?.map((entry, index) => (
                      <React.Fragment key={index}>
                        {entry.subject && (
                          <>
                            <b>{entry.subject}:</b>{" "}
                          </>
                        )}
                        {entry.time}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                  <p className="text-[#A3A3A3] text-[14px]">
                    Evening Class: Not Available
                  </p>
                </>
              )}
            </div>


            <div className="bg-[#1E1E1E] mx-[17px] text-white h-[400px] rounded-xl pt-[66px] pb-[66px] items-center justify-center text-center">
              <b className="text-[36px] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Saturday
              </b>
              <p style={{
                marginTop: scheduleData.Morning.length > 4 ? "10px" : "80px",
                fontSize: scheduleData.Morning.length > 4 ? "14px" : "20px",
              }}>Morning:</p>
              <b className=" mx-auto font-noto text-[16px] text-center" style={{
                fontSize: scheduleData.Morning.length > 4 ? "14px" : "20px",
              }}>
                {scheduleData.WeekendMorning.length > 0 ? (
                  scheduleData.WeekendMorning.map((entry, index) => (
                    <React.Fragment key={index}>
                      {entry.subject && (
                        <>
                          <b >{entry.subject}:</b>{" "}
                        </>
                      )}
                      {entry.time}
                      <br />
                    </React.Fragment>
                  ))
                ) : (
                  <>
                    <p className="text-[#A3A3A3] mb-1">
                      Not Available
                    </p>
                  </>
                )}
              </b>
              <p className="mt-[20px]"
                style={{
                  fontSize: scheduleData.Morning.length > 4 ? "14px" : "20px",
                }}>Afternoon:</p>
              <b className=" mx-auto text-[14px] font-noto font-bold lg:text-[16px]text-center"
                style={{
                  fontSize: scheduleData.Morning.length > 4 ? "14px" : "20px",
                }}>
                {scheduleData.WeekendAfternoon.length > 0 ? (
                  scheduleData.WeekendAfternoon.map((entry, index) => (
                    <React.Fragment key={index}>
                      {entry.subject && (
                        <>
                          <b >{entry.subject}:</b>{" "}
                        </>
                      )}
                      {entry.time}
                      <br />
                    </React.Fragment>
                  ))
                ) : (
                  <>
                    <p className="text-[#A3A3A3] mb-1">
                      Not Available
                    </p>
                  </>
                )}
              </b>
              <p className="text-[#A3A3A3] mb-1">
                Evening Class: Not Available
              </p>
            </div>
          </div>
        </div>

        <div className=" border-[1px] border-primary-color-200 px-10 rounded-md">
          <AppFaq faqsData={FaqsData} />
        </div>

        <div className="mt-10 flex w-full justify-center mb-20">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://forms.gle/fjNU4BXFwG6Cf3FZ6"
            className="btn w-full text-center max-w-[15rem]"
          >
            Apply
          </Link>
        </div>
      </section>
    </main>
  );
};

export default ProgramInfo;
