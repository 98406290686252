import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../../components/testimonialCarousel/TestimonialCarousel.css'; 

const ResponsiveTestimonial = ({ testimonials }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 12000,
    beforeChange: (current, next) => setActiveIndex(next),
    ref: sliderRef,
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="flex w-full flex-col md:flex-row justify-between items-center gap-5 md:gap-10">
      <div className="w-full md:w-[55%] self-center content-center">
        <Slider className="w-full md:max-w-[35em]" {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="w-full h-fit flex flex-col items-center">
                  <div className="w-[6em] h-[6em] rounded-full overflow-hidden border-2 border-white">
                    <img src={testimonial.image} alt={testimonial.desc} />
                  </div>
                  <p
                    className={
                      activeIndex === index
                        ? "testimonial-text active pt-5 pb-5"
                        : "testimonial-text"
                    }
                  >
                    {testimonial.testimonial}
                  </p>
                  <div>
                  <p
                    className={
                      activeIndex === index ? "author active" : "author"
                    }
                  >
                    {testimonial.author}
                  </p>
                  <p className={
                      activeIndex === index ? "author active text-sm text-[#A3A3A3]" : "author"
                    }>

                    {testimonial.track}
                  </p>
                  </div>
                 
              
            </div>
          ))}
        </Slider>
      </div>
      <div className="hidden md:block h-[10em] bg-white w-[2px]"></div>
      <div className="w-full md:w-[44%] flex items-center">
        <div className="w-full grid grid-cols-3 gap-4 place-items-center">
          {testimonials.map((testimonial, index) => (
            <img
              key={index}
              src={testimonial.image}
              alt={testimonial.desc}
              onClick={() => handleThumbnailClick(index)}
              className={
                activeIndex === index ? "object-cover w-[4rem] h-[4rem] rounded-full " : " object-cover w-[4rem] h-[4rem] rounded-full opacity-50"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTestimonial;
