import { useEffect, useState } from "react";
import trainingTestimonials from "../trainingTestimonials";
import ResponsiveTestimonial from "./responsiveTestimonial";
import VideoSection from "./VideoSection";
import Aos from "aos";
import { Link } from 'react-router-dom';

const TestimonialComponent = () => {
  const [pageState, setPageState] = useState(0);

  useEffect(()=>{
    Aos.init({duration: 1000})
  
  }, [])

  return (
    <section className="min-h-fit flex items-center flex-col w-full mt-32">
      <div className="p-1 rounded-md flex justify-between items-center relative border-2 border-[#BBBBBB] w-fit gap-2">
        <p
          className={`tab-class ${pageState === 0 ? "selected-tab" : ""}`}
          onClick={() => setPageState(0)}
        >
          Pictures
        </p>
        <p
          className={`tab-class ${pageState === 1 ? "selected-tab" : ""}`}
          onClick={() => setPageState(1)}
        >
          Video
        </p>
      </div>
      <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] px-[clamp(1em,8vw,5em)] text-center mt-8 mb-2 md:my-0">
        Why Our Students Love Us
      </h1>
      <p className="content font-normal text-[#D4D4D4] px-[clamp(1em,8vw,5em)] text-center text-[clamp(0.7em,3vw,1em)]">
        Read & Watch through our happy client testimonies
      </p>
      <div className="w-full mt-10 px-[clamp(1em,8vw,5em)]">
      {pageState === 0 ? (
          <ResponsiveTestimonial testimonials={trainingTestimonials} />
        ) : (
          <VideoSection />
        )}
      </div>
      <Link
            to="/testimonials"
            smooth={true}
            className="btn btn_lg"
          >
            View more
      </Link>
    </section>
  );
};
export default TestimonialComponent;
