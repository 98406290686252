import { useState } from 'react';

const TrainingCard = ({ title, body, track, className, img, noButton, url }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const maxLength = 200; // Set the maximum length of the text to show

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const truncateBody = (text) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const isTruncated = body.length > maxLength;

    return (
        <div 
            className={`${!noButton ? 'border-animation bg-[rgba(255,255,255,0.1)]' : 'bg-[#212121] rounded-lg'} font-noto p-5 ${className} relative flex flex-col justify-between overflow-hidden transition-all duration-300 ease-in-out`}
            style={{ 
                height: isExpanded ? 'auto' : '20em', 
                minHeight: '20em', 
                maxHeight: isExpanded ? 'none' : '20em',
                width: '20em'
            }}
        >
            <p className="font-normal text-m text-[#A3A3A3] z-50 relative mb-8 whitespace-pre-wrap">
                {isExpanded || !isTruncated ? body : truncateBody(body)}
                {isTruncated && (
                    <span onClick={toggleExpand} className="text-primary-color-500 cursor-pointer">
                        {isExpanded ? ' read less' : '...read more'}
                    </span>
                )}
            </p>
            <div className="flex items-center mt-auto">
                <div className={`rounded-full w-[3.5em] h-[3.5em] overflow-hidden z-50 relative`}>
                    {img ? <img src={img} alt="testimonials" className="w-full h-full object-cover" /> : <div className='blur'></div>}
                </div>
                <div className="ml-4">
                    <h2 className="text-lg z-50 relative">{title}</h2>
                    <p className="text-sm text-[#A3A3A3] z-50 relative">{track}</p>
                </div>
            </div>
        </div>
    );
}

export default TrainingCard;
