import { Link } from "react-router-dom"
import "../../styles/Internship.css"

const InternshipCard = ({details}) => {
  return (
    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-16 lg:justify-self-center'>
        {details.map((intern)=>{
            return(
                <div key={intern.id} className='intern_card'>
                    <h2>{intern.position}</h2>
                    <ul>
                        {intern.duty_list.slice(0,2).map((list, index)=>{
                            return <li key={index} >{list} </li>
                        })}
                    </ul>
                        <Link to={`/internship/${intern.name}`} className="btn">Learn More</Link>
                </div>
            
            )
        
        
        })}
    
    </div>
  )
}

export default InternshipCard