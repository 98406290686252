import AppFaq from "./trainingPage/Faq";
import ProgramInfo from "./trainingPage/ProgramInfo";

export const SelectedTraining = () => {
    return (
        <div className=' grid grid-rows-2 w-full flex'>
          <ProgramInfo/>
          <AppFaq/>
      </div>
      
      
    );
};
export default SelectedTraining;