import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BrandCarousel.css";
import brands from "./brand-data";

const BrandCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },

    ],
  };

  return (
    <div className="main_container">
      <div className="brand_carousel_container">
        <Slider {...settings} className="slick_slide">
          {brands.map((brand, index) => (
            <div key={index} className="brand_item">
              <img src={brand.logo} alt={brand.name} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BrandCarousel;
