import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full flex justify-left py-10 px-20">
      <section className="w-full max-w-3xl mt-16">
        <h1 className="text-4xl mb-8 text-left">Privacy Policy</h1>
        <main className="space-y-6 shadow-md rounded-lg">
          <h3 className="text-2xl">Intellyjent Privacy Policy</h3>
          <h4 className="text-xl font-semibold">About Intellyjent</h4>
          <p>JVEC Solutions is the developer, operator, and publisher of online games for the web and mobile on various platforms and devices. The Company operates the mobile application for Android of the app “Intellyjent” and also the website <a href="https://www.jvecsolutions.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">https://www.jvecsolutions.com</a>.</p>
          
          <h4 className="text-xl font-semibold">Who are we?</h4>
          <p>JVEC Solutions is the developer and operator of the platforms, including the website and all services supplied by it (all, the ”Platform”).</p>
          
          <h4 className="text-xl font-semibold">What’s this Privacy Policy about?</h4>
          <p>We have created this Privacy Policy because we highly value your Personal Data and information. Please read it, as it includes important information regarding your Personal Data and information. We make our best efforts to protect our users’ privacy and to comply with privacy protection laws and regulations, including the Nigerian Data Protection Regulation (the “NDPR”).</p>
          <p>Please read this Privacy Policy carefully. For any questions or concerns, please contact us via our email address: <a href="mailto:hello@jvecsolutions.com" className="text-blue-500">hello@jvecsolutions.com</a>.</p>
          
          <p><strong>Consent:</strong> By using the Platform or allowing someone else to use it on your behalf, you give your consent to our collection, use, disclosure, transfer, and storage of any Personal Data and information received by us as a result of such use, in accordance with this Privacy Policy.</p>
          <p>Under the NDPR, our classification is of a Controller of the Personal Data provided to us by you. As such, we will make our best efforts to ensure the following:</p>
          
          <h5 className="text-lg font-semibold">Data we hold:</h5>
          <ol className="list-decimal ml-5 space-y-2">
            <li>We shall use it lawfully, fairly, and transparently.</li>
            <li>We shall collect it for specific and legitimate purposes.</li>
            <li>We shall limit the amount of Personal Data to be adequate and relevant.</li>
            <li>It shall be as accurate and up-to-date as possible.</li>
            <li>We shall limit the possibility of your identification to the minimum possible.</li>
            <li>We shall keep it secure.</li>
          </ol>
          
          <h5 className="text-lg font-semibold">Which Information do we collect?</h5>
          <p><strong>1. Personal Data:</strong></p>
          
          <h5 className="text-lg font-semibold">How do we use your Information?</h5>
          <p><strong>1. Personal Data:</strong> Any Personal Data we collect is used in accordance with this Privacy Policy and may be used as follows:</p>
          <p><strong>a. Access and Use:</strong> Any Personal Data provided to us by you to obtain access to any functionality of the Platform may be used to provide you with access to the required functionality and to monitor your use of such functionality.</p>
          <p><strong>b. Company’s Business Purposes:</strong> Any Personal Data provided to us by you may be used to help us improve the functionality of the Platform, better understand our users, protect against, identify or address wrongdoing, enforce our Terms of Use, and generally manage the Platform and our business.</p>
          <p><strong>c. A Specific Reason:</strong> Any Personal Data provided to us by you for a specific reason may be used in connection with that specific reason.</p>
          <p><strong>d. Marketing:</strong> Any Personal Data provided to us by you may be used to contact you in the future for our marketing and advertising purposes, including to inform you about new services or Platforms we believe might interest you and to develop promotional or marketing materials and provide those materials to you.</p>
          <p><strong>e. Statistics:</strong> Any Personal Data provided to us by you may be used for statistical reports containing aggregated information.</p>
          <p><strong>2. Non-Personal Data:</strong> Since Non-Personal Data cannot be used to identify you, we may use such data in any way permitted by law.</p>
          
          <h5 className="text-lg font-semibold">General Terms for Data Transfer</h5>
          <p>Your Personal Data may be kept, processed, or transferred to servers located outside the country you live in. We shall keep records of all activities relevant to Personal Data transfer, as required by the NDPR. We shall only transfer your Personal Data to companies that comply with the NDPR, subject to your consent to this Privacy Policy. Our cloud is hosted by Heroku in the United States, which complies with the NDPR.</p>
          
          <h5 className="text-lg font-semibold">Duration of Data Retention</h5>
          <p>We shall retain your Personal Data and activity logs for at least 24 months after the last time you accessed the Services, unless you specifically request us to delete your Personal Data earlier, in which case we will delete it as requested.</p>
          
          <h5 className="text-lg font-semibold">Which Information do we share with Third Parties?</h5>
          <p><strong>1. Personal Data:</strong> We have no intention of selling your Personal Data to any third party. However, there are circumstances in which we may disclose, share, or transfer your Personal Data without further notice, as follows:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li><strong>a. Required by Law:</strong> If we believe in good faith that disclosure is required by law, including orders by any governmental entity, court, or any other judicial entity.</li>
            <li><strong>b. To Prevent Wrongdoing:</strong> If we believe in good faith that disclosure is required to prevent any illegal activity, fraud, or civil wrong.</li>
            <li><strong>c. Business Purposes:</strong> As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution, or similar event, Personal Data may be part of the transferred assets. You acknowledge and agree that any successor to or acquirer of the Company will continue to have the right to use your Personal Data and information under the terms of this Privacy Policy.</li>
          </ul>
          <p><strong>2. Non-Personal Data:</strong> Since Non-Personal Data cannot be used to identify you, we may disclose such data in any way permitted by law.</p>
          
          <h5 className="text-lg font-semibold">Links to Other Web Sites</h5>
          <p>Intellyjent may contain links to other websites and/or third-party services. We are not responsible for the privacy policies of such websites and/or services, and we advise you to review their privacy policies.</p>
          
          <h5 className="text-lg font-semibold">Storage and Security</h5>
          <p>We are strongly committed to protecting your Personal Data and information, and we will take reasonable technical steps to keep your Information secured and protect it from loss, misuse, or alteration. If you notice a risk or any security violations, report to us at <a href="mailto:hello@jvecsolutions.com" className="text-blue-500">hello@jvecsolutions.com</a>, so we can resolve it as soon as possible.</p>
          
          <h4 className="text-xl font-semibold">Other Terms</h4>
          <p><strong>1. Changes to This Privacy Policy:</strong> The Platform and our business may change from time to time. As a result, we may need to make changes to this Privacy Policy. We reserve the right to update or modify this Privacy Policy at any time. Modifications to this Privacy Policy will be indicated by the “Last Updated” date at the top. Please review this Privacy Policy periodically. Continued use of the Services following any Modifications constitutes acceptance of those Modifications. If any Modification is not acceptable to you, cease accessing, browsing, and otherwise using the Platform.</p>
          
          <h5 className="text-lg font-semibold">2. Direct Messages</h5>
          <ul className="list-disc ml-5 space-y-2">
            <li><strong>a.</strong> The Company may send you information regarding its services, including advertisements, via short messages or emails.</li>
            <li><strong>b.</strong> By using the Platform, you consent to receive such messages. You may remove this consent by sending an email to <a href="mailto:hello@jvecsolutions.com" className="text-blue-500">hello@jvecsolutions.com</a>. However, we may still send direct messages we believe are critical for your use of the platform.</li>
          </ul>
          
          <h5 className="text-lg font-semibold">3. Cookies</h5>
          <p>We may use Cookies for the proper and continuous operation of the Platform, including the collection of statistics, verifications, and modifications.</p>
          
          <h5 className="text-lg font-semibold">4. Third Party Advertisements</h5>
          <ul className="list-disc ml-5 space-y-2">
            <li><strong>a.</strong> As part of the services, some third-party advertisements may appear on the Platform or website. Such advertisements are provided by an external supplier that adapts content according to user habits.</li>
            <li><strong>b.</strong> Such third-party services may use Cookies. These services are subject to the third party’s privacy policy, not this document.</li>
          </ul>
          
          <h5 className="text-lg font-semibold">5. Dispute Resolution</h5>
          <p>The laws of Lagos State shall govern this Privacy Policy. Any dispute arising in connection with this Privacy Policy shall be brought to the competent court in Lagos State, Nigeria, which shall have exclusive jurisdiction.</p>
          
          <h5 className="text-lg font-semibold">Contact Information</h5>
          <h5 className="text-lg font-semibold">JVEC Solutions</h5>
          <p>13 Rev. Ogunbiyi street, Ikeja GRA, Lagos State.</p>
          <small>If you have any questions about this Policy, please contact us at <a href="mailto:hello@jvecsolutions.com" className="text-blue-500">hello@jvecsolutions.com</a>.</small>
        </main>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
