

 const testimonials= [
  {
    image: "/images/testimonial_page/Felix.jpg",
    testimonial: "JVEC Solutions is the real deal; I had a seamless work with them throughout the building of our website from conceptualization to delivery. The feedback was amazing as they made sure it suited what we wanted, keeping in touch with us all the way. I'll surely recommend them any day anytime.",
    author: "Felix",
    track: "Founder, Pent Agency (USA)"
  },
  {
    image: "/images/testimonial_page/Mrs Praise.jpeg",
    testimonial: "The classes are very easy to follow and the facilitators have been very supportive; the fact that I can engage the facilitators one-on-one and they are always ready to answer my questions no matter how silly they sound is something I don’t think I would have been able to find anywhere else. I have started our industry-based project with the support of my facilitator and I am sure after this I will be more than confident to handle the next one myself.",
    author: "Praise (United Kingdom)",
    track: "Data Analytics Track"
  },
  {
    image: "/images/testimonial_page/Emma Nwokeji testimonial.jpg",
    testimonial: "Compared to other companies, JVEC Solutions has been far and above the best in every area. While building our website, the customer service has been prompt and straightforward and the actual services are well documented and logical to our needs. We are very excited working with JVEC Solutions. I will gladly recommend them to anyone.",
    author: "Emmanuel",
    track: "CEO/Founder, Careers Verified (Nigeria)"
  },
  {
    image: "/images/testimonial_carousel/avatar1.webp",
    desc : "SME success story image",
    testimonial: "Few months ago, I saw an advert on Data Analytics Training posted by Social media influencer - Inneh Joseph on LinkedIn. The training paved way for me in my new job - the first task given to me as Head of HR was in Excel; when I opened the Excel file, I saw the first topic (filter) treated in my first class. I recommend JVEC to all HR and Admin personnel, you need proficiency in Excel to report to management.",
    author: "Olumide (Nigeria)",
    track: "Data Analytics Track"
  },
  {
    image: "/images/testimonial_carousel/avatar3.jpeg",
    desc : "client success story image",
    testimonial: "The Customer Support and relations at JVEC are second to none; they give premium support to customers’ challenges and go the extra mile to check on customer’s well-being.",
    author: "Peace (Nigeria)",
    track: "Data Analytics Track"
  },
  {
    author: "Shadrack (Germany)",
    track: "Project Management Track",
    desc : "SME success story image",
    testimonial: "I recently completed a project management course with JVEC, and the experience was outstanding. The training was comprehensive, well-structured, and provided valuable insights that have significantly enhanced my skills. JVEC’s professionalism and dedication to excellence is truly commendable. I highly recommend their services to anyone looking to advance their project management capabilities.",
    image: "/images/testimonial_page/default.jpeg"
  },
  {
    image: "/images/testimonial_page/default.jpeg",
    desc : "SME success story image",
    testimonial: "Finding a platform that helps secure an internship after learning is challenging. Enrolling in the backend development program at JVEC not only solved this issue but also offered invaluable one-on-one mentor sessions. The practical classes are suitable for programmers at any level. The project-based training and internship opportunities provide essential real-world experience. Plus, the flexible payment options make it financially accessible.",
    author: "Bisola (Nigeria)",
    track: "Software Engineering Track"
  }
]

export default testimonials;