import React from "react";
import "./Categories.css";
import { useState } from "react";

const capitalize =(word)=>{
   return word.charAt(0).toUpperCase() + word.slice(1);

}
const Categories = ({ categories, filterItems }) => {
    const [isActive, setIsActive] = useState(false);
    // const [isActiveIndex, setIsActiveIndex] = useState(null);
    const handleClick = (index) => {
        setIsActive(!isActive);
        // setIsActiveIndex(isActiveIndex);
    };
   
  return (
    <div className="categories_wrapper  flex_wrapper flex_apart">
      {categories.map((category, index) => {
        return (
          <div className="categories">
            <button 
                type="button"  
                key={index} 
                onClick={()=> {filterItems(category); handleClick(index)}} 
                className={`cat_btn ${isActive ? 'active' : ''}`}>
                    {capitalize(category)}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Categories;
