import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import blogData from "../../components/blogComponent/blogData";
import ErrorPage from "../../components/ErrorPage";
import "./Blog.css";

const Blog = () => {
  const { name } = useParams();
  const {pathname} = useLocation()
      // so the page scrolls to top on render
    useEffect(()=>{
        window.scrollTo(0, 0)
    
    },[pathname]);
  const blog = blogData.find((blog) => blog.name === name);
  // condition if blog name is not found in data then it returns the error page
  if (!blog) return <ErrorPage />;

  // to share to social media 
    const shareOnFacebook = () => {
    const url = encodeURIComponent(`/blog/${blog.name}`);
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    window.open(facebookShareUrl, '_blank', 'width=600,height=400');
  };

  const shareOnTwitter = () => {
    const url = encodeURIComponent(`/blog/${blog.name}`);
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=Check%20out%20this%20blog%20post!`;
    window.open(twitterShareUrl, '_blank', 'width=600,height=400');
  };

  return (
    <article className="blog">
      <div className="blog_container">
        <h1>{blog.title}</h1>
        <div className="blog_img_wrapper">
          <img src={blog.image} alt={blog.name} />
        </div>
      </div>
      <div className="blog_content">
        {blog.content.map((item, index) => {
          return (
            <div key={index}>
              <p className="intro">{item.introduction} </p>
              <div className="subtopic">
                {item.subtopics.map((topic, index) => {
                  return (
                    <div key={index}>
                      <h2>{topic.subheading} </h2>
                      
                      <p>
                        {topic.description.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
 
      </div>
      <div className="share_container">
        <h3>You can share the article on your social media platforms.</h3>
        <div className="share_button flex_wrapper flex_apart">
          <button onClick={shareOnFacebook}> <img src="./images/blog/Facebook.svg" alt="facebook icon" /> <small>Share on Facebook</small></button>
          <button onClick={shareOnTwitter}> <img src="./images/blog/twitter.svg" alt="twitter icon" /> <small>Share on X</small></button>
        </div>
      </div>
    </article>
  );
};

export default Blog;
