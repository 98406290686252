import { useEffect } from "react";

const AccountDeletion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <div className="mt-20 mb-20 ml-16 flex justify-left">
        <section className="w-full max-w-2xl px-4">
          <h1 className="text-4xl mb-4 text-left">Accounts</h1>
          <main>
            <h3 className="text-xl mb-2">How to delete your account and related information</h3>
            <p className="mb-4">Follow the steps below on the Intellyjent App to delete your account:</p>
            <h4 className="text-lg mb-2">Steps to Delete Your Account:</h4>
            <ul className="list-disc list-inside mb-4 space-y-1">
              <li>Log in to your account.</li>
              <li>Click on the "Account" menu.</li>
              <li>Scroll to the bottom of the screen and click on "Delete Account."</li>
              <li>You can state your reasons for deleting your account (optional).</li>
              <li>Click on the "Delete" button.</li>
              <li>Confirm your decision again by selecting "Delete" on the next screen.</li>
            </ul>
            <h4 className="text-lg mb-2">Data that are deleted:</h4>
            <ul className="list-disc list-inside space-y-1">
              <li>Full name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Date of Birth</li>
              <li>Gender</li>
              <li>Country</li>
              <li>Password</li>
              <li>Username</li>
              <li>Profile image</li>
            </ul>
          </main>
        </section>
      </div>
    </div>
  );
};

export default AccountDeletion;
