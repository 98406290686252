import { Link } from 'react-router-dom';

const TrainingCard = ({ title, body, className, img, noButton, url }) => {
    return (
        <div className={`${!noButton ? 'border-animation bg-[rgba(255,255,255,0.1)]' : 'bg-[#212121] rounded-lg'} font-noto p-5 ${className} relative flex flex-col justify-between h-full`}>
            <div className="flex flex-col">
                <div className={`rounded-full w-[2.5em] h-[2.5em] ${img ? "" : "blur-class"} z-50 relative`}>
                    {img ? <img src={img} alt="ICT training courses" /> : <div className='blur'></div>}
                </div>

                <h2 className="text-lg mt-6 mb-2 z-50 relative">{title}</h2>
                <p className="font-normal text-sm text-[#A3A3A3] z-50 relative">{body}</p>
            </div>
            {!noButton && (
                <button className="border-2 border-primary-color-500 px-4 py-[0.3rem] rounded-md z-50 relative mt-4 self-start">
                    <Link smooth={true} duration={500} to={url || `/training/${title.toLowerCase().split(' ')[0]}`}>Read More</Link>
                </button>
            )}
        </div>
    );
}

export default TrainingCard;
