import React, { useEffect } from 'react'
import { useParams,useLocation  } from 'react-router-dom'
import internData from '../../components/internComponent/internData'
import useOpen from './useOpen'
import "../../styles/Internship.css"


const InternshipPost = () => {
    const {internship} = useParams()
    const {pathname}= useLocation()
    const {value : isOpenPosition} = useOpen();

    // to find the intern data that the name matches with the path
    const intern =internData.find((intern)=>intern.name === internship)

    // so the page scrolls to top on render
    useEffect(()=>{
        window.scrollTo(0, 0)
    
    },[pathname]);

  return (
    <main className='single_intern_post flex_wrapper'>
        <div className="image-wrapper">
            <img src={intern.imageUrl} alt={intern.position} />
        </div>
        <div className="intern_duties">
            <h3>{`Responsibilities As A ${intern.position}`} </h3>
            {intern.duty_list.map((ele, index)=>{
                return(
                    <ul key={index} >
                        <li>{ele}</li>
                    </ul>
                
                )
            })}
            {isOpenPosition? (
                    <a href={intern.formLink} target='_blank'rel="noreferrer" className='btn'>Apply Now</a>
                ) : (
                    <button disabled className='btn disabled'>Closed</button>
                )}
        </div>
    </main>
  )
}

export default InternshipPost