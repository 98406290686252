import React, { useEffect } from "react";

export const Openings = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="relative lg:pt-36 pt-20">
            <div className="bg-[url(/public/images/openings/openings_bg.png)] bg-cover bg-center bg-no-repeat min-h-[40vh] min-h-[50vh] lg:min-h-[80vh] filter brightness-90">
             <div className="absolute w-full flex flex-col bottom-[50%] top-[50%] font-noto items-center justify-center text-center text-white">
                {/* <img src='./images/padlock_image.png' className="mb-2" alt="" style={{ width: '18%' }} /> */}
                <div className="w-[80%]">
                    <h2 className="font-bold lg:text-[50px] text-[24px] mb-2">OPENINGS</h2>
                    {/* <h3 className="lg:text-[20px] text-[14px] lg:pb-20 ">
                        Hi there! There are no available openings at the moment, please kindly check back. Thank you.
                    </h3> */}
                </div>
            </div>
                
            </div>
            <div className="mt-10 px-5 lg:px-20">
                <h1 className="text-2xl lg:text-4xl font-bold mb-4">Senior Django Developer</h1>
                <br></br>
                <h1 className="text-xl lg:text-3xl font-bold mt-8 mb-4">Job Summary:</h1>
                <p className="lg:text-2xl">We are currently building Africa’s first full-scale educational social network which promises to be a game-changer in Social networking.</p>
                <p className="lg:text-2xl">We seek an experienced and skilled Senior Django Developer to join our dynamic team. In this role, you will be responsible for designing, developing, and maintaining complex web applications using the Django framework. You will work closely with cross-functional teams, including product managers, designers, and developers, to deliver high-quality, scalable, and secure solutions.</p>
                <h2 className="text-xl lg:text-3xl font-bold mt-8 mb-4">Responsibilities:</h2>
                <ul className="list-disc lg:text-2xl ml-5 mb-4">
                    <li>Develop and maintain robust web applications using the Django framework, adhering to best practices and industry standards.</li>
                    <li>Design and implement RESTful APIs, integrations with third-party services, and efficient data models.</li>
                    <li>Optimize the performance of Django applications by implementing caching mechanisms, database optimization techniques, and load balancing strategies.</li>
                    <li>Contribute to the development of reusable and modular code, following principles of clean code and object-oriented programming.</li>
                    <li>Collaborate with other developers, reviewing code and providing guidance on best practices and architectural decisions.</li>
                    <li>Participate in the entire software development lifecycle, including requirement gathering, design, implementation, testing, and deployment.</li>
                    <li>Ensure the security of Django/ Django Rest Framework applications by implementing appropriate security measures, such as input validation, authentication, and authorization mechanisms.</li>
                    <li>Stay up-to-date with the latest trends, technologies, and best practices related to Django and web development.</li>
                    <li>Mentor and provide technical guidance to junior developers, fostering a culture of knowledge sharing and continuous learning.</li>
                    <li>Collaborate with DevOps teams to streamline the deployment and monitoring processes for Django applications.</li>
                    <li>Collaborate with our training team on Software Engineering training.</li>
                </ul>
                <h2 className="text-xl lg:text-3xl font-bold mt-8 mb-4">Qualifications:</h2>
                <ul className="list-disc ml-5 lg:text-2xl mb-4">
                    <li>Bachelor's degree in Computer Science, Information Technology, or a related field, or equivalent practical experience.</li>
                    <li>At least 3 years of experience in web development, with a strong focus on the Django framework.</li>
                    <li>Solid understanding of Python programming language and its ecosystem.</li>
                    <li>Experience with relational databases (e.g., PostgreSQL) and ORM (Object-Relational Mapping) concepts.</li>
                    <li>Familiarity with version control systems, such as Git, and continuous integration/continuous deployment (CI/CD) practices.</li>
                    <li>Knowledge of caching mechanisms (e.g., Redis) and message queues (e.g., Celery).</li>
                    <li>Experience with cloud platforms (e.g., AWS, Azure, GCP, Cloudinary) and containerization technologies (e.g., Docker, Kubernetes) is a plus.</li>
                    <li>Strong problem-solving and analytical skills, with the ability to break down complex requirements into actionable tasks.</li>
                    <li>Excellent communication and collaboration skills, with the ability to work effectively in a team environment.</li>
                </ul>
                <h2 className="text-xl lg:text-3xl font-bold mt-8 mb-4">Benefits:</h2>
                <ul className="list-disc ml-5 mb-4 lg:text-2xl">
                    <li>Competitive salary and benefits package.</li>
                    <li>Opportunities for professional growth and career advancement.</li>
                    <li>Collaborative and dynamic work environment.</li>
                    <li>Flexible work arrangements.</li>
                    <li> Ongoing training and learning opportunities</li>
                </ul>
                <br></br>
                <p className=" mb-10 lg:text-2xl ">If you are a passionate Django developer with a strong track record of delivering high-quality web applications, we invite you to apply for this exciting opportunity. Please submit your CV to careers@jvecsolutions.com</p>
                <br></br>

            </div>
        </div>
    );
};

export default Openings;