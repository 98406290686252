import React, { useEffect } from "react";
import blogData from "../../components/blogComponent/blogData";
import { Link } from "react-router-dom";
import './Blog.css';
import Categories from "../../components/blogComponent/Categories";
import RecentBlogs from "../../components/blogComponent/RecentBlogs";
import { useState } from "react";
import DesktopRecentBlogs from "../../components/blogComponent/DesktopRecentBlogs";
import Aos from "aos";

const allCategories = [
  "all",
  ...new Set(blogData.map((blog)=> blog.category)),
]

const BlogList = () => {
  useEffect(()=>{
    Aos.init({duration: 2000})
    window.scrollTo(0, 0);
    
    },[]);
  // finds the blog that is featured ie set to true
  const featuredPost = blogData.find((blog) => blog.isFeatured === true);
  // filters all the blogs except the featured one since all the other blogs featrured property are set to false
  const otherPost = blogData.filter((blog)=>blog.isFeatured !== true);

  const [categories, setCategories]= useState(allCategories);
  const [blog, setBlog] = useState(otherPost)


  const filterBlog = (category)=>{
    if(category === "all"){
      setBlog(otherPost)

      return;
    }
    const newCategory = otherPost.filter((post)=> post.category === category)
    setBlog(newCategory);
  }
  // newsletter state
  const [email, setEmail]= useState("")

  return (
    <div className="blog_list">
        <header className="featured_post flex_wrapper flex_start">
          <div className="text_col">
            <h5>Featured Post</h5>
            <h1>{featuredPost.title} </h1>
            {featuredPost.content.map((post, index)=>{
              return <p key={index}>{post.introduction.slice(0,95)}</p>
            })}
            <Link to={`/blog/${featuredPost.name}`} className="btn btn_lg featured_btn" >Read more</Link>
          </div>

          <div className="img_col">
            <div className="img_wrap">
              <img src={featuredPost.image} alt={featuredPost.name} />
            </div>
          </div>
        </header>

      <div className="blog_list_container">
        <div className="mini_nav flex_wrapper flex_apart">
          <h4>Recent blog articles</h4>
          <Categories categories={categories} filterItems={filterBlog} />
        </div>
        <div className="recent_blogs">
          <div className="desktop_blog_slider">
            <DesktopRecentBlogs blogContent={blog}/>
          </div>
          <div className="tab_blog_grid">
            <RecentBlogs blogContent={blog} />
          </div>
        </div>
      </div>

      {/* <div className="newsletter_wrapper">
        <div className="newsletter flex_wrapper flex_apart">
          <div className="newsletter_form_col">
            <h5>Don't miss out on our exciting news and exclusive content. Enter your email below to subscribe to our daily newsletters</h5>
            
            <div className="newsletter_form_control ">
              <form action="" method="POST">
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" placeholder="johndoe@email.com" onChange={(e)=>setEmail(e.target.value)} />
                <button type="submit" className="btn btn_lg">Subscribe</button>
              </form>
            </div>
          </div>
          <div className="newsletter_img_col">
            <div>
              <img src="./images/home/open_3d_ball.svg" alt="" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BlogList;
