
// import TypewriterTraining from "typewriter-effect";

const TestimonialHero = () =>  {


    // const [currentSubheadingIndex, setCurrentSubheadingIndex] = useState(0);

  return (
    <header className="lg:min-h-screen flex justify-center items-center">
        <div className="w-full relative min-h-[40vh] md:min-h-[80vh] h-[30vh] bg-cover bg-center bg-[url(/public/images/testimonial_page/maintestimonial_bg.jpeg)] flex justify-center items-center flex-col px-[clamp(1em,8vw,5em)]">
          <h1 className="header_text text-[clamp(2.7em,6vw,4.5em)] max-w-[95%] md:max-w-[70%] lg:max-w-[95%] lg:pt-60 pt-10 text-center">
          Customer Testimonials
          </h1>
          <h3 className="font-normal text-[clamp(0.9em,2vw,1.25em)] lg:max-w-[75%] text-center">
          Through the years, we have seen our rigorous commitment to excellence propel our valued clients to achieving their corporate and career goals. Hear from some of them.
        </h3>
        </div>
      </header>
  );
};
export default TestimonialHero;

