import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import videoData from "./videoData";

const VideoSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: !isPlaying,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setActiveIndex(next),
  };

  const handleThumbnailClick = (index) => {
    if (currentVideoIndex !== null) {
      // Pause the current video if one is playing
      const videoElement = document.getElementById(`video-${currentVideoIndex}`);
      if (videoElement) {
        videoElement.pause();
      }
    }
    setActiveIndex(index);
    setCurrentVideoIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
  };

  const handleVideoPause = () => {
    setIsPlaying(false);
  };

  return (
    <div className="flex w-full flex-col md:flex-row justify-between items-center gap-5 md:gap-10">
      <div className="w-full md:w-[55%] self-center content-center">
        <Slider ref={sliderRef} {...settings}>
          {videoData.map((video, index) => (
            <div key={index} className="video-container">
              <video
                id={`video-${index}`}
                src={video.videoUrl}
                controls
                className="w-full h-full"
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="hidden md:block h-[10em] bg-white w-[2px]"></div>
      <div className="w-full md:w-[44%] flex items-center">
        <div className="w-full grid grid-cols-3 gap-4 place-items-center">
          {videoData.map((video, index) => (
            <img
              key={index}
              src={video.thumbnail}
              alt={video.title}
              onClick={() => handleThumbnailClick(index)}
              className={
                activeIndex === index
                  ? "object-cover w-[4rem] h-[4rem] rounded-full "
                  : "object-cover w-[4rem] h-[4rem] rounded-full opacity-50"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
