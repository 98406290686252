import { HiOutlineLightBulb } from "react-icons/hi";
import "../../styles/Internship.css"
import { useState } from "react";
import internData from "../../components/internComponent/internData";
import InternshipCard from "./InternshipCard";
import useOpen from "./useOpen";

const Internship = () => {
   const{value: isOpenPosition} = useOpen()
  const[internPosition]=useState(internData)


  const infoCards = [
    {
      id: 1,
      title: "Our Values",
      icon: <HiOutlineLightBulb style={{ color: 'var(--secondary-color)', fontSize:40  }} />,
      list: [
              "Diligence | We believe that Hardwork has no substitute.",
              "Excellence | We believe that Excellence is prime to stand out from the crowd.",
              "Integrity | We believe that nothing sells better like keeping to your words.", 
              ]
    },
    {
      id: 2,
      title: "What You Can Expect",
      icon: <HiOutlineLightBulb style={{ color: 'var(--secondary-color)', fontSize:40 }} />,
      list: [
              "Joining a young and vibrant team of Tech enthusiasts.",
              "Gaining industry experience while working on real projects.",
              "Growing confidence while working with senior colleagues.", 
              "Landing a full-time role at the completion of internship program."
              ]
    },
    {
      id: 3,
      title: "About you",
      icon: <HiOutlineLightBulb style={{ color: 'var(--secondary-color)', fontSize:40  }} />,
      list: [
              "You’re a Tech enthusiast.",
              "You’re able to think out of the box.",
              "You are familiar with the Tech industry.", 
              "You live in Lagos state.", 
              ]
    },

    {
      id: 4,
      title: "Entitlement & Benefits",
      icon: <HiOutlineLightBulb style={{ color: 'var(--secondary-color)', fontSize:40  }} />,
      list: [
              "Monthly salary",
              "Data Subscription",
              "Free Learning Resources", 
              "Attend industry events and conferences", 
              "Network with other Tech professionals", 
              ]
    },
  ]

  const processInfo = [
    {
      id:1,
      step: "The recruitment happens on online channels with live Interview sessions on Google Meet."
    },
    {
      id:2,
      step: "A remote exercise to demonstrate your skills and give you a taste of what working with JVEC could be like"
    },
    {
      id: 3,
      step: "We will send you an interview guide so you can best prepare yourself."
    },
    {
      id: 4,
      step:"On average our recruitment process lasts for 3 weeks and offers usually follows in 48 hours."
    },
    {
      id: 5,
      step: "Thank you for considering joining JVEC Solutions. We can not wait to learn more about you!"
    }
    
  ]

  return (
    <div className="internship_page">
      <header>
        <div className="background_layer">
          <h1>About Internship</h1>
          <p>({isOpenPosition? "Now Open":"Now Closed" })</p>
        </div>
      </header>
      <div className="flex_wrapper">
        <div className="header-txt">
          <h2>Internship Openings at JVEC Solutions</h2>
          <p>{isOpenPosition? null : "This Program is now closed, Check back Later"} </p>
        </div>
        <div className="parag-txt">
          <p>Who are we? JVEC Solutions is a technology-focused firm specializing in crafting innovative business solutions tailored to empower enterprises across diverse scales in today’s dynamic technological landscape. Our core ethos, the Philosophy of Excellence, serves as our unwavering commitment to consistently provide unparalleled quality to our valued clients. This commitment drives our rigorous dedication to every project, ensuring our clients get maximum value for money.</p>

          <p>At JVEC, we wholeheartedly embrace the principle that the Customer is King. Consequently, we approach our relationships with clients with the utmost respect and prioritize their needs, recognizing the royalty they represent in our business realm.</p>
        </div>
      </div>
      <section className="more_info flex_wrapper flex_apart">
        <div className="info_cards">
        {infoCards.map((element)=>{
          return(
            <div className="info_card" key={element.id}>
              <span>{element.icon} </span>
              <h3>{element.title} </h3>
              {element.list.map((ele, index)=>{
                return(
                  <ul className="list" key={index}>
                    <li>{ele}</li>
                  </ul>
                )  
              })}
            
            </div>
          )
        })}
        </div>
        <div className="image_container">
          <div className="image_wrapper sm-wrap">
            <img src="/images/home/full_3d_ball.svg" alt="" />
          </div>
          <div className="image_wrapper lg-wrap">
            <img src="/images/home/full_3d_ball.svg" alt="" />
          </div>
        </div>
      </section>
      <main className="internship_cards_container">
        <h2>Your Responsibilities</h2>
        <p>Below are your resposibilities here at Jvec</p>
        <div className="intern_container">
          <InternshipCard details={internPosition}/>
        </div>
      </main>
      <div className="process grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-16 lg:justify-self-center">
          <div className="heading">
            <h3>Our Hiring Process</h3>
            {isOpenPosition? <p>(Please note that this is an HYBRID role.) </p> : (<div> <p>This Program is now closed, Check back Later</p>  <p>(Please note that this is an HYBRID role.) </p> </div> )}
          </div>
          {processInfo.map(({id, step})=>{
            return(
              <div className="grid-item" key={id} >
                <span>
                  <small>Step {id} </small>
                </span>
                <p>{step} </p>
              </div>
            
            )
          })}
 
      </div>
    </div>
  );
};

export default Internship;
