import React from "react";
import { Link } from "react-router-dom";


const DesktopRecentBlogs = ({blogContent}) => {

  return (
    <div className="desktop_blog_wrapper">
      {blogContent.map((blog, id) => {
        const { title, image, name, content} = blog;
        return (
          <div className="blog_card" key={id}>
            <div className="blog_image">
              <img src={image} alt={name} />
            </div>
            <h2>{title} </h2>
            {content.map((post, index)=>{
              return <p key={index}>{post.introduction.slice(0,199)}...</p>
            })}
            <Link to={`/blog/${name}`}>Read more</Link>
          </div>
        );
      })}
    </div>
  );
};

export default DesktopRecentBlogs;
