const internData = [
    {
        id:1,
        name : "digital-marketer",
        position: "Digital Marketer",
        imageUrl: "./images/internship/digital-marketer-intern.png",
        formLink: "https://forms.gle/EwDkmVj8p5k9Rr9M9",
        duty_list:[
            "Develop and execute strategic digital marketing campaigns across various channels (e.g., social media, email marketing, paid advertising, search engine optimization).",
            "Create engaging and informative content, including blog posts, social     media posts, email newsletters, and website copy.",
            "Manage and optimize social media presence, grow follower base, and engage with audiences on relevant platforms.",
            "Run effective pay-per-click (PPC) advertising campaigns and analyze    performance metrics.",
            "Conduct keyword research and implement on-page SEO best practices to improve website search ranking.",
            "Track and analyze campaign performance using web analytics tools (e.g., Google Analytics) and report results to stakeholders.",
            "Stay up-to-date on the latest digital marketing trends and technologies.",
            "Assist with other marketing initiatives as needed.",
            "Co-facilitate Digital marketing training along with our training team."
        ] 
    },

    {   
        id:2,
        name: "business-development-management",
        position: "Business Development management",
        imageUrl: "./images/internship/bdm-intern.png",
        formLink: "https://forms.gle/xf1NstJdCPmXbWak7",
        duty_list:[
            "Conduct market research and identify new business opportunities aligned with our strategic goals.",
            "Develop and maintain relationships with potential clients and industry partners.",
            "Qualify leads, understand client needs, and tailor solutions to address their specific challenges.",
            "Develop and present compelling proposals and presentations that highlight the value proposition of our offerings.",
            "Negotiate contracts and ensure mutually beneficial agreements.",
            "Manage the sales cycle from initial contact to deal closure.",
            "Assist with sales pipeline management and reporting.",
            "Stay up-to-date on industry trends and competitor landscape.",
            "Co-facilitate Business Development management training along with our training team."
        ] 
    },
    {
        id:3,
        name:"data-analyst",
        position: "Data Analyst",
        imageUrl: "./images/internship/data-analyst-intern.png",
        formLink: "https://forms.gle/ERxdupVCxGDuUxaB9",
        duty_list:[
            "Collect, clean, and prepare data from diverse sources, including databases, APIs, and log files.",
            "Perform exploratory data analysis to identify patterns, trends, and anomalies.",
            "Develop and execute SQL queries to retrieve and manipulate data as needed.",
            "Analyze data using statistical methods and data visualization tools to uncover insights.",
            "Create clear and concise reports and presentations to communicate findings to both technical and non-technical audiences.",
            "Support business stakeholders in utilizing data-driven insights to make informed decisions.",
            "Stay up-to-date on emerging data analysis tools and techniques.",
            "Co-facilitate Data Analytics training along with our training team."
        ] 
    },
    {
        id:4,
        name: "data-scientist",
        position: "Data Scientist",
        imageUrl: "./images/internship/data-science-intern.png",
        formLink: "https://forms.gle/X8VzhtjvYDY9MTZr5",
        duty_list:[
            "Work closely with stakeholders to understand business needs and translate them into data-driven solutions.",
            "Collect, clean, and manage various data sources, ensuring data quality and integrity.",
            "Explore and analyze data using statistical and machine learning techniques to identify trends, patterns, and insights.",
            "Develop and evaluate predictive models to solve specific business problems.",
            "Visualize and communicate complex data findings effectively to both technical and non-technical audiences.",
            "Stay up-to-date on emerging data science tools and technologies.",
            "Contribute to the continuous improvement of data science processes within the team.",
            "Co-facilitate Data Science training along with our training team."
        ] 
    },
    {
        id: 5,
        name:"ui-ux-designer",
        position: "UI/UX Designer",
        imageUrl: "./images/internship/ui-ux-intern.png",
        formLink: "https://forms.gle/MTCahF14CoGdFcuaA",
        duty_list:[
            "Conduct user research and gather user feedback to understand user needs and pain points.",
            "Design user interfaces (UI) for websites, mobile apps, and other ICT products.",
            "Create user experience (UX) flows and sitemaps to ensure a smooth user journey.",
            "Develop prototypes and mockups to communicate design ideas and gather feedback.",
            "Conduct usability testing and iterate on designs based on user feedback.",
            "Collaborate with developers and engineers to ensure designs are translated into functional products.",
            "Stay up-to-date on the latest design trends and best practices in UI/UX design.",
            "Co-facilitate Product design training along with our training team."

        ] 
    },
    {   
        id: 6,
        name: "motion-graphic-designer",
        position: "Motion/Graphics Designer",
        imageUrl: "./images/internship/motion-designer-intern.png",
        formLink: "https://forms.gle/5FKJoiYYFYMASo7m6",
        duty_list:[
            "Develop and animate engaging motion graphics for various digital platforms,including websites, social media, explainer videos, and marketing materials.",

            "Collaborate with designers and marketing teams to understand project objectives and translate them into visually compelling narratives.",

            "Create storyboards, animatics, and style guides to define the visual direction of projects.",

            "Animate using industry-standard software like Adobe After Effects, Cinema 4D and Adobe Animate.",

            "Incorporate sound effects and music to enhance the overall impact of motion graphics.",

            "Stay up-to-date on current trends and techniques in motion graphics design.",

            "Contribute to the development and maintenance of the company's brand identity through consistent visual style.",

            "Co-facilitate Motion Design training along with our training team."
        ] 
    },
    {
        id:7,
        name: "cybersecurity",
        position: "Cybersecurity",
        imageUrl: "./images/internship/cyber-security-intern.png",
        formLink: "https://forms.gle/57mS19uSbkncsjJk7",
        duty_list:[
            "Assist senior security professionals in identifying and assessing cybersecurityrisks and vulnerabilities.",
            "Participate in vulnerability scanning, penetration testing, and security assessments.",
            "Implement and configure security controls, such as firewalls, intrusion detection systems, and endpoint protection software.",
            "Monitor security logs and alerts for suspicious activity and investigate potential incidents.",
            "Respond to security incidents according to established procedures.",
            "Document security findings, recommendations, and incident response activities.",
            "Stay up-to-date on emerging cybersecurity threats and trends.",
            "Contribute to the continuous improvement of the company's security posture.",
            "Co-facilitate Cybersecurity training along with our training team."
    
        ] 
    },
    {
        id: 8,
        name: "wordpress-developer",
        position: "Wordpress Developer",
        imageUrl: "./images/internship/wordpress-intern.png",
        formLink: "https://forms.gle/ugX2DcgvX5AMyZbq8 ",
        duty_list:[
            "Develop and maintain custom WordPress themes and plugins based on design specifications and user needs.",
            "Implement responsive design principles to ensure websites look great on all devices.",
            "Optimize website performance for speed and search engine ranking",
            "Perform regular website backups and security updates.",
            "Troubleshoot and resolve technical issues with WordPress and plugins.",
            "Collaborate with designers and content creators to integrate their work seamlessly into the website.",
            "Stay up-to-date on the latest WordPress trends and best practices.",
            "Co-facilitate WordPress development training along with our training team."

        ] 
    },
    {
        id:9,
        name: "mobile-developer",
        position: "Mobile Developer",
        imageUrl: "./images/internship/django-intern.png",
        formLink: "https://forms.gle/MzwkzqaDP36Ze1rX6",
        duty_list:[
            "Develop and maintain native mobile applications for Android and/or iOS platforms.",
            "Implement user interfaces and features based on wireframes and design specifications.",
            "Integrate with APIs and third-party services to enhance app functionality.",
            "Conduct unit and integration testing to ensure code quality and stability.",
            "Debug and troubleshoot technical issues to optimize app performance.",
            "Stay up-to-date with the latest mobile development technologies and trends.",
            "Contribute to code reviews and collaborate effectively within the development team.",
            "Co-facilitate Mobile Development training along with our training team."
        ] 
    },
    {
        id:10,
        name: "project-manager",
        position: "Project Manager",
        imageUrl: "./images/internship/django-intern.png",
        formLink: "https://forms.gle/DxA2JZXLNHmJmEAw9",
        duty_list:[
            "Assist senior project managers in the planning, execution, and monitoring of ICT projects.",
            "Develop and maintain project plans, including timelines, budgets, and resource allocation.",
            "Track project progress, identify and mitigate risks, and ensure deliverables are met on time and within budget.",
            "Facilitate communication between project stakeholders, keeping them informed of progress and addressing any concerns.",
            "Manage project documentation, reports, and meeting minutes.",
            "Conduct user acceptance testing and ensure project outputs meet quality standards.",
            "Contribute to the continuous improvement of project management processes within the team.",
            "Co-facilitate Project Management training along with our training team."
        ] 
    },
    {
        id:11,
        name: "product-manager",
        position: "Product Manager",
        imageUrl: "./images/internship/django-intern.png",
        formLink: "https://forms.gle/zbNHmkiVVuYyEsmWA",
        duty_list:[
            "Conduct market research and competitive analysis to identify new product opportunities.",
            "Define product vision, roadmap, and key features based on user needs and business goals.",
            "Develop product requirements documents and user stories.",
            "Collaborate with designers to create intuitive and user-friendly interfaces.",
            "Work with developers to ensure product features are built according to specifications.",
            "Track product development progress and manage product launch activities.",
            "Analyze user feedback and data to measure product success and identify areas for improvement.",
            "Stay up-to-date on emerging technologies and trends in the ICT industry.",
            "Co-facilitate Product Management training along with our training team."
        ] 
    },
    {
        id:11,
        name: "content-writer",
        position: "Content Writer",
        imageUrl: "./images/internship/django-intern.png",
        formLink: "https://forms.gle/8v9wqyRtYqXEizEY6",
        duty_list:[
            "Conduct thorough research to understand complex ICT topics and translate them into clear, concise, and engaging content",
            "Develop high-quality content that aligns with brand voice, SEO best practices, and target audience needs.",
            "Craft various content formats, including blog posts, website copy, social media content, white papers, and email campaigns.",
            "Optimize content for search engines and social media engagement.",
            "Proofread and edit content for accuracy, grammar, and clarity",
            "Collaborate with internal teams like marketing, product, and sales to understand content needs and objectives.",
            "Stay up-to-date on current trends and best practices in content marketing and the ICT industry.",
            "Co-facilitate Content writing training along with our training team.",
        ] 
    },
    {
        id:12,
        name: "fullstack-developer",
        position: "FullStack Developer",
        imageUrl: "./images/internship/django-intern.png",
        formLink: "https://forms.gle/XhRCXMF5bLmphHGP9",
        duty_list:[
            "Develop and maintain both front-end and back-end components of web applications.",
            "Write clean, well-documented, and maintainable code using industry best practices.",
            "Collaborate with designers and UI/UX specialists to translate design mockups into functional interfaces.",
            "Implement features and functionalities according to user stories and technical specifications.",
            "Contribute to unit testing, integration testing, and other quality assurance processes.",
            "Debug and troubleshoot issues identified during development and testing",
            "Stay up-to-date with emerging technologies and trends in the web development landscape.",
            "Participate in code reviews and knowledge sharing within the development team.",
            "Co-facilitate Software engineering training along with our training team",
        ] 
    },

]

export default internData;