const videoData = [
    {
      title: "Louisa Ufuah",
      videoUrl: "https://res.cloudinary.com/bookchamp/video/upload/v1718283456/JVEC%20Video%20Testimonials/Louisa_Ufuah_bbmesm.mp4",
      thumbnail: "/images/testimonial_page/default.jpeg",
    },
    {
      title: "Ruth Okon",
      videoUrl: "https://res.cloudinary.com/bookchamp/video/upload/v1718283457/JVEC%20Video%20Testimonials/Ruth_Okon_hyq5cr.mp4",
      thumbnail: "/images/testimonial_page/Ruth Okon.png",
    },
    {
      title: "Ruth Aijoba",
      videoUrl: "https://res.cloudinary.com/bookchamp/video/upload/v1718283454/JVEC%20Video%20Testimonials/Ruth_Aijoba_f8ejjw.mp4",
      thumbnail: "/images/testimonial_page/ruth aijoba.jpg"
    },
    {
        title: "Ufuoma Niameh",
        videoUrl: "https://res.cloudinary.com/bookchamp/video/upload/v1718283465/JVEC%20Video%20Testimonials/Ufuoma_Niameh_kuci4m.mp4",
        thumbnail: "/images/testimonial_page/ufuomah img4.jpg"
      },
      {
        title: "Olumide Oduwole",
        videoUrl: "https://res.cloudinary.com/bookchamp/video/upload/v1718283454/JVEC%20Video%20Testimonials/Olumide_Oduwole_fokyzv.mp4",
        thumbnail: "/images/testimonial_carousel/avatar1.webp"
      },
      {
        title: "Thelma Obayuwana",
        videoUrl: "https://res.cloudinary.com/bookchamp/video/upload/v1718283454/JVEC%20Video%20Testimonials/Thelma_Obayuwana_i8nthw.mp4",
        thumbnail: "/images/testimonial_carousel/avatar4.jpg"
      }
  ];
  
  export default videoData;
  