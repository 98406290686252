import React from "react";
import { Link } from "react-router-dom";
import './RecentBlogs.css';

const RecentBlogs = ({blogContent}) => {
  return (
    <>
      {blogContent.map((blog) => {
        const { id, title, image, content, name } = blog;
        return (
          <div className="blog_card" key={id}>
            <div className="blog_image">
              <img src={image} alt={name} />
            </div>
            <h2>{title} </h2>
            {content.map((post, index)=>{
              return <p key={index}>{post.introduction.slice(0, 199)}...</p>
            })}
            <Link to={`/blog/${name}`}>Read more</Link>
          </div>
        );
      })}
    </>
  );
};

export default RecentBlogs;
