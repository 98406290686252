import { useState } from 'react';

const useOpen = (initialValue = true) => {
  const [value, setValue] = useState(initialValue);

  const setOpenValue = (newValue) => {
    setValue(newValue);
  };

  return {
    value,
    setOpenValue,
  };
};

export default useOpen;