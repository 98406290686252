import React, { useState, useEffect, useRef } from "react";
import LoadingIcons from "react-loading-icons";
import "./ContactUs.css";
import emailjs from "@emailjs/browser";
import CustomModal from "./CustomModal";

function ContactUs({className, textClass}) {
  const [fullName, setFullName] = useState("");
  const [subject, setSubject] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const form = useRef();

  useEffect(() => emailjs.init("37Xe_Ym34yYpEi4Rt"), []);

  const handleModalClose = () => {
    setShowModal(false);
  };


  const handleEmailJS = async (e) => {
    e.preventDefault();
    setLoading(true);
    await emailjs
      .sendForm(
        "service_rxlmx03",
        "template_qy4fgs8",
        e.target,
        "37Xe_Ym34yYpEi4Rt"
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          setShowModal(true);
          setFullName("");
          setSubject("");
          setEmailAddress("");
          setPhoneNumber("");
          setMessage("");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          alert("Failed to submit form. Please try again later.");
          setLoading(false);
          setFullName("");
          setSubject("");
          setEmailAddress("");
          setPhoneNumber("");
          setMessage("");
          e.target.reset();
        }
      );
    e.target.reset();
  };

  return (
    <div
      className={`wrapper-div flex flex-col object-cover ${className} lg:flex-row lg:justify-center lg:items-center lg:flex bg-contact lg:mt-20 ${
        loading ? "loading" : ""
      }`}
      id="contact-form-id"
    >
      {loading && (
        <div className="h-[100%] absolute inset-0 flex justify-center items-center">
          {" "}
          <LoadingIcons.BallTriangle style={{ width: "100%" }} className="" />
        </div>
      )}

      <CustomModal isOpen={showModal} onClose={handleModalClose} />

      <div className="px-5 text-left mt-20 lg:mt-0 lg:w-[50%]">
        <p className="font-semibold my-5 lg:text-[18px] word-spacing">READY TO GET STARTED?</p>
        <h2
          style={{ textAlign: "left", paddingBottom:"0px" }}
          className="font-bold text-[36px] lg:pb-0 pb-0"
        >
          Send us an Email
        </h2>
        <p style={{paddingTop:"24px"}} className={`text-[16px] lg:pt-0 pt-0 word-spacing ${textClass}`}>
          Join numerous corporations, organizations, and businesses as they
          scale their teams, tap into new market opportunities and build
          innovative products with JVEC Solutions.
        </p>
        <br></br>
        <p className={`text-[16px] word-spacing ${textClass}`}>
          Join thousands of students who are ready to learn new skills or take
          their career to the next level by enrolling in one of our training
          programs today.
        </p>
      </div>

      <form
        ref={form}
        onSubmit={handleEmailJS}
        className="lg:w-[50%] pt-10 pb-10 px-5"
      >
        <div className="input_styles">
          <p className="label pt-4 pb-3">FULL NAME</p>
          <input
            placeholder="John Doe"
            type="text"
            name="to_name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>
        <div className="input_styles">
          <p className="label pt-4 pb-3">SUBJECT</p>
          <input
            placeholder="Inquiry"
            type="text"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="input_styles">
          <p className="label pt-4 pb-3">EMAIL ADDRESS</p>
          <input
            placeholder="johndoe@mail.com"
            type="email"
            name="to_email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            required
          />
        </div>
        <div className="input_styles">
          <p className="label pt-4 pb-3">PHONE NUMBER</p>
          <input
            placeholder="08133457361"
            type="tel"
            name="phone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="input_styles" style={{ paddingBottom: "1px" }}>
          <p className="label pt-4 pb-3">COMMENT OR MESSAGE</p>
          <textarea
            id="text_area"
            placeholder="Type in Your Message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className="">
          <button
            type="submit"
            className="btn button-div justify-center items-center block md:block"
            disabled={loading}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
