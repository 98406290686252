import "../styles/AboutUs.css";
import { Link } from "react-router-dom";
import ReusableValues from "../components/reuseableFunctions/AboutUsCards";
import ContactUs from "../components/ContactUs";
import { useEffect } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import gsap from "gsap/all";
import { ScrollTrigger } from "gsap/all";

const AboutUs = () => {
  let content = useRef();
  let process = useRef();
  const { training } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      gsap
        .timeline()
        .from("h1", {
          y: 500,
          opacity: 0,
          duration: 1,
        })
        .from(
          ".content",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.2"
        ).from(
          ".read",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.1"
        );
    }, content);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: process.current,
          start: "-30% 90%",
          end: "top center",
          // markers: true
        },
      })
      .fromTo(
        process.current,
        { y: 200 },
        {
          y: 0,
          duration: 1.4,
          ease: "power1.inOut",
        }
      );

    return () => ctx.revert(); // cleanup
  }, [training]);

  return (
    <div className="main-container">
      <div className="about-hero">
        <div className="about-hero-wrapper">
          <div className="about-text-col">
            <h1>About Us</h1>
            <p>
            Our relentless pursuit of excellence is the driving force behind our success.
            </p>
          </div>
          <div className="about-img-col">
            <div>
              <img className="rotating-globe" src="/images/about_us/globe.svg" alt={""} />
            </div>
          </div>
        </div>
      </div>

      <div className="mission-vision">
        <div className="vision">
          <img src="/images/about_us/our_vision.png" alt={""} />
          <div className="vision-section">
            <h4>Our vision</h4>
            <p>
              “To be the trusted partner for businesses seeking to leverage technology to improve decision-making, unlock new opportunities, and achieve transformative growth.”
            </p>
          </div>
        </div>
        <div className="mission">
          <img src="/images/about_us/our_mission.png" alt={""} />
          <div className="vision-section">
            <h4>Our Mission</h4>
            <p>
              “We bridge the gap between technology and business, delivering customized ICT solutions that drive efficiency, growth, and competitive advantage.”
            </p>
          </div>
        </div>
      </div>

      <div className="represent-wrapper lg:mt-10">
        <div className="represent-main ">
          <div className="person">
            <img src="/images/about_us/about_us.jpg" alt={" "} />
          </div>
          <div className="represent space-y-7">
            <h1>What we represent</h1>
            <p className="text-[18px] font-noto">
              JVEC Solutions is a technology-focused firm specializing in
              crafting innovative business solutions tailored to empower
              enterprises across diverse scales in today’s dynamic technological
              landscape.
            </p>
            <p className="text-[18px] font-noto">
              Our core ethos, the Philosophy of Excellence, serves as our
              unwavering commitment to consistently provide unparalleled quality
              to our valued clients. This commitment drives our rigorous
              dedication to every project, ensuring our clients get maximum
              value for money.
            </p>
            <p className="text-[18px] font-noto">
              At JVEC, we wholeheartedly embrace the principle that the Customer
              is King. Consequently, we approach our relationships with clients
              with the utmost respect and prioritize their needs, recognizing
              the royalty they represent in our business realm.
            </p>
            <Link
              to="/services"
              smooth={true}
              duration={500}
              className="btn btn_lg"
            >
              <div className="button-text text-white hover:text-amber-500 text-sm font-bold font-['Noto Sans'] tracking-tight">
                Get Started
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="core  pb-[70px] pt-10 lg:px-0 px-10">
        <h1 className="text-[60px]">Our Core Values</h1>
        <h3 className="lg:pt-0 font-normal text-[25px] text-center">Our Values shape everything we do and how we do it.</h3>
      </div>

      <div className="core-value-wrapper">
        <div className="cores-values ">
          <ReusableValues
            image="/images/about_us/diligence.svg"
            h5Text={"Diligence"}
            text={
              "At JVEC, we believe that whatever is worth doing, is worth doing well. This mindset is responsible for our conscientious approach to achieving quality service delivery."
            }
          />
          <ReusableValues
            image="/images/about_us/integrity.svg"
            h5Text={"Integrity"}
            text={
              "With well-concentrated efforts, we have grown in the art of earning clients’ trust even from our first transaction. We preserve earned trust with transparent deals and meeting clients’ deadlines."
            }
          />
          <ReusableValues
            image="/images/about_us/excellence.svg"
            h5Text={"Excellence"}
            text={
              "Over the years, we have built our brand on the Foundation of Excellence. Our services are always coated with that “Touch of Perfection” because our esteemed clients deserve nothing less than the best."
            }
          />
        </div>
      </div>

      <div className="business lg:px-0 px-10 grid place-items-center">
        <h1 className="font-semibold">Why Businesses Choose Us</h1>
        <p>At JVEC, we leave you with a lasting impression.</p>
      </div>

      <div className="business-value-wrapper">
        <div className={"business-values flex justify-center"}>
          <ReusableValues
            image="/images/about_us/money_icon.png"
            h5Text={"Value For Money"}
            text={
              "At JVEC, 'Value for money' is not a promise but a commitment. We go the extra mile to ensure you get every penny's worth of excellence in technology solutions."
            }
          />
          <ReusableValues
            image="/images/about_us/diamond_icon.png"
            h5Text={"Consistency in Quality"}
            text={
              "At JVEC, the heart of our success is the unwavering principle of 'consistency in quality,' ensuring your satisfaction, project after project."
            }
          />
          <ReusableValues
            image="/images/about_us/percentage-square.png"
            h5Text={"100% Delivery Rate"}
            text={
              "At JVEC, We don't just meet expectations; we exceed them. Join us for flawless, on-time ICT delivery."
            }
          />
        </div>
      </div>
      <ContactUs />
    </div>
  );
};

export default AboutUs;
