import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileNavStateContext from "../../context/navContext";
import LinkList from "./components/LinkedList";

const TabletMobileNav = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { setNavVisibility } = useContext(MobileNavStateContext);
  const location = useLocation();
  const selectedStyle = {
    color: "#4169E1",
    paddingBottom: "4px",
    paddingTop: "4px",
    fontWeight: "700",
    fontSize: "14px",
    fontFamily: "sans-serif",
  };

  return (
    <div className="lg:hidden fixed w-full h-full z-40">
      <div
        className=" fixed w-full h-full z-40 bg-[rgba(0,0,0,0.8)]"
        onClick={() => setNavVisibility(false)}
      ></div>
      <nav className="right-0 fixed ml-auto h-full overflow-y-auto w-4/6 md:w-1/2 bg-[#0F0F0F] max-h-screen top-0 rounded-l-xl px-5 py-5 z-50">
        <div className="flex justify-between items-center">
          <Link to="/">
            <div
              className="group"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={
                  isHovered
                    ? "/images/logo/logo_4k_x_2k_orange.png"
                    : "/images/logo/logo_4k_x_2k.png"
                }
                alt={
                  isHovered
                    ? "JVEC Solutions Logo - Orange"
                    : "JVEC Solutions Logo - Blue"
                }
                className="h-8 w-auto cursor-pointer"
              />
            </div>
          </Link>
          <div
            className="cursor-pointer p-2"
            onClick={() => setNavVisibility(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                d="m7 7l10 10M7 17L17 7"
              />
            </svg>
          </div>
        </div>
        <div className="menu flex flex-col gap-4 mt-10">
          <Link
            to="/"
            className={`text-white text-xl mr-2 linkButton ${
              location.pathname === "/"
                ? "selected linkButton selected-responsive-nav"
                : ""
            }`}
            onClick={() => setNavVisibility(false)}
            style={location.pathname === "/" ? selectedStyle : {}}
          >
            Home
          </Link>

          {/* Services Dropdown */}
          <div className="relative item">
            <input
              type="checkbox"
              className="drop-handler hidden"
              id="services"
            />
            <label for="services" className="flex items-center justify-between">
              <h3
                className={`text-white focus:outline-none flex items-center linkButton ${
                  location.pathname.startsWith("/service") ? "selected " : ""
                }`}
              >
                <Link to={'/services'}>Services </Link>
              </h3>
              <svg
                className="ml-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="m13.171 12l-4.95-4.95l1.415-1.413L16 12l-6.364 6.364l-1.414-1.415l4.95-4.95Z"
                />
              </svg>
            </label>
            <div className="sub-menu ml-4 overflow-hidden">
              <div className="mt-4"></div>
              <LinkList
                dismissNav={() => setNavVisibility(false)}
                items={[
                  "Website Development",
                  "Mobile App Development",
                  "Software Development",
                  "Digital Marketing",
                  "Product Design",
                  "Graphics Design",
                  "Motion Design / Video Editing",
                  "Consulting",
                  "Corporate Training",
                  "Data Consultancy",
                  "Product Management",
                  "Project Management",
                ]}
                urls={[
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    "/services/data_consultancy",
                    "/services/product_management",
                    "/services/project_management",
                  ]}
                baseClass="linkButton"
                selectedStyle={selectedStyle}
              />
            </div>
          </div>

          {/* About Us Dropdown */}
          <div className="relative item">
            <input type="checkbox" className="drop-handler hidden" id="about" />
            <label for="about" className="flex items-center justify-between">
              <h3
                className={`text-white focus:outline-none flex items-center linkButton ${
                  location.pathname.startsWith("/service") ? "selected " : ""
                }`}
              >
                About
              </h3>
              <svg
                className="ml-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="m13.171 12l-4.95-4.95l1.415-1.413L16 12l-6.364 6.364l-1.414-1.415l4.95-4.95Z"
                />
              </svg>
            </label>
            <div className="sub-menu ml-4 overflow-hidden">
              <div className="mt-4"></div>
              <Link
                to="/about"
                className={`text-white text-sm mb-2 linkButton block ${
                  location.pathname === "/about"
                    ? "selected selected-responsive-nav"
                    : ""
                }`}
                onClick={() => setNavVisibility(false)}
                style={location.pathname === "/about" ? selectedStyle : {}}
              >
                About Us
              </Link>
              <Link
                to="/internship"
                style={location.pathname === "/internship" ? selectedStyle : {}}
                onClick={() => setNavVisibility(false)}
                className={`text-white block text-sm linkButton mb-2  ${
                  location.pathname === "/internship"
                    ? "selected linkButton selected-responsive-nav"
                    : ""
                }`}
              >
                Internship
              </Link>
            </div>
          </div>

          <Link
            to="/training"
            onClick={() => setNavVisibility(false)}
            className={`text-white mr-2 linkButton ${
              location.pathname === "/training"
                ? "selected linkButton selected-responsive-nav"
                : ""
            }`}
            style={location.pathname === "/training" ? selectedStyle : {}}
          >
            Training
          </Link>

          {/* More Dropdown */}
          <div className="relative mr-2 item">
            <input type="checkbox" className="drop-handler hidden" id="more" />
            <label for="more" className="flex items-center justify-between">
              <h3
                className={`text-white focus:outline-none flex items-center linkButton ${
                  location.pathname.startsWith("/service") ? "selected " : ""
                }`}
              >
                More
              </h3>
              <svg
                className="ml-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="m13.171 12l-4.95-4.95l1.415-1.413L16 12l-6.364 6.364l-1.414-1.415l4.95-4.95Z"
                />
              </svg>
            </label>
            <div className="sub-menu ml-4 overflow-hidden">
              <div className="mt-4"></div>
              <Link
                to="/blogs"
                onClick={() => setNavVisibility(false)}
                className={`text-white text-sm linkButton p-2 pb-1 mb-2 block ${
                  location.pathname === "/blogs"
                    ? "selected linkButton selected-responsive-nav"
                    : ""
                }`}
                style={location.pathname === "/blogs" ? selectedStyle : {}}
              >
                Blog
              </Link>
              <Link
                to="/portfolio"
                onClick={() => setNavVisibility(false)}
                className={`text-white block text-sm linkButton mb-2 p-2 pb-1  ${
                  location.pathname === "/portfolio"
                    ? "selected linkButton selected-responsive-nav"
                    : ""
                }`}
                style={location.pathname === "/portfolio" ? selectedStyle : {}}
              >
                Portfolio
              </Link>
              <Link
                to="/testimonials"
                onClick={() => setNavVisibility(false)}
                className={`text-white block text-sm linkButton mb-2 p-2 pb-1  ${
                  location.pathname === "/portfolio"
                    ? "selected linkButton selected-responsive-nav"
                    : ""
                }`}
                style={location.pathname === "/testimonials" ? selectedStyle : {}}
              >
                Testimonials
              </Link>
            </div>
          </div>
        </div>
        <Link to="/contact" className="">
          <Link
            onClick={() => setNavVisibility(false)}
            to="/contact"
            className="btn btn_lg" style={{width: "100%"}}
          >
            Contact Us
          </Link>
        </Link>
      </nav>
    </div>
  );
};
export default TabletMobileNav;
